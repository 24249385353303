import { UPDATE_STATE } from "./actions";

export const newsReducer = (state, { type, payload } = {}) => {
  switch (type) {
    case UPDATE_STATE:
      return { ...state, ...payload };
    default:
      throw new Error("Unexpected action");
  }
};
