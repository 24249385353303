import React, { createContext, useReducer, useContext } from "react";

import { newsReducer } from "./reducers";

export const GlobalStateContext = createContext();

export function GlobalStateProvider(props) {
  const initialValue = {
    lang: null,
    category: undefined,
    items: null,
    cursor: 0 /* Which page infinite scroll should fetch next. */,
    isLoading: true,
  };

  return (
    <GlobalStateContext.Provider value={useReducer(newsReducer, initialValue)}>
      {props.children}
    </GlobalStateContext.Provider>
  );
}

export const useStateValue = () => useContext(GlobalStateContext) || [];
