/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// exports.onClientEntry = () => {
//   if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.getRegistrations('/').then(registrations => {
//       registrations.map(reg => reg.unregister())
//     })
//   }
// }

import React from "react";
import { GlobalStateProvider } from "./src/components/common/GlobalState";

require("matchmedia-polyfill");
require("matchmedia-polyfill/matchMedia.addListener");
// require("smooth-scroll")('a[href*="#"]');
require("fetch-polyfill");

export function wrapRootElement({ element }) {
  return <GlobalStateProvider>{element}</GlobalStateProvider>;
}

export async function onClientEntry() {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    console.log("RUN BROWSER API");
    require("smooth-scroll")('a[href*="#"]');
  }

  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }

  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement(`img`);
  if (
    testImg.style.objectFit === `undefined` ||
    testImg.style.objectPosition === `undefined`
  ) {
    import(`object-fit-images`)();
    console.log(`Object-fit/Object-position are polyfilled`);
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // hack to override `shouldUpdateScroll` behavior to bypass useQueryParams in product-checkout
  if (location.search.includes("filters=")) {
    return false;
  }
  const currentPosition = getSavedScrollPosition(location);
  return currentPosition || [0, 0];
};
